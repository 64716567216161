
export const routesConfig = [
    {
		appname: 'ibuhoo',
		componentLogin: {
			relativePath: '/login',
			component: 'Login'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePassword'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPassword'
		},
		componentSendEmailResetPassword: {
			relativePath: '/alert',
			component: 'Alert'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'Alert'
		}
	},
    {
		appname: 'license',
		componentLogin: {
			relativePath: '/login',
			component: 'Login'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePassword'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPassword'
		},
		componentSendEmailResetPassword: {
			relativePath: '/alert',
			component: 'Alert'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'Alert'
		}
	},
    {
		appname: 'colfactura',
		componentLogin: {
			relativePath: '/login',
			component: 'Login'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePassword'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPassword'
		},
		componentSendEmailResetPassword: {
			relativePath: '/alert',
			component: 'Alert'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'Alert'
		}
	},
    {
		appname: 'colnomina',
		componentLogin: {
			relativePath: '/login',
			component: 'Login'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePassword'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPassword'
		},
		componentSendEmailResetPassword: {
			relativePath: '/alert',
			component: 'Alert'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'Alert'
		}
	},
    {
		appname: 'biometria',
		componentLogin: {
			relativePath: '/login',
			component: 'Login'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePassword'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPassword'
		},
		componentSendEmailResetPassword: {
			relativePath: '/alert',
			component: 'Alert'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'Alert'
		}
	},
    {
		appname: 'confronta',
		componentLogin: {
			relativePath: '/login',
			component: 'Login'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePassword'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPassword'
		},
		componentSendEmailResetPassword: {
			relativePath: '/alert',
			component: 'Alert'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'Alert'
		}
	},
    {
		appname: 'firmaya',
		componentLogin: {
			relativePath: '/login',
			component: 'UserCertificate'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePasswordCertificate'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPasswordCertificate'
		},
		componentSendEmailResetPassword: {
			relativePath: '/alert',
			component: 'Alert'
		}		,
		defaultAlert: {
			relativePath: '/alert',
			component: 'Alert'
		}
	},
	{
		appname: 'myselfcert',
		componentLogin: {
			relativePath: '/login',
			component: 'LoginUko'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePasswordUko'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPasswordUko'
		},
		componentSendEmailResetPassword: {
			relativePath: '/check-email',
			component: 'SendEmailResetPasswordUko'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'InfoUko'
		}
	},
	{
		appname: 'bemyself',
		componentLogin: {
			relativePath: '/login',
			component: 'LoginUko'
		},
		componentChangePassword: {
			relativePath: '/changepassword',
			component: 'ChangePasswordUko'
		},
		componentResetPassword: {
			relativePath: '/resetpassword',
			component: 'ResetPasswordUko'
		},
		componentSendEmailResetPassword: {
			relativePath: '/check-email',
			component: 'SendEmailResetPasswordUko'
		},
		defaultAlert: {
			relativePath: '/alert',
			component: 'InfoUko'
		}
	}
];

export const getComponentValue = (appname, key, value) => {
	const appConfig = routesConfig.filter((x) => x.appname === appname);
	let path = "";
	// eslint-disable-next-line array-callback-return
	appConfig.filter(function (object) {
        path = object[key][value];
    });

	return path;
};