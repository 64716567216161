import React from "react";
import { Provider } from "react-redux";

import store from "./store/store";
import Routes from "./routes/routes";
import { APP_HEADER_NAME, TENANT_HEADER_NAME } from "./config/config";

import { ThemeProvider } from "@material-ui/core/styles";
import { apps, muiTheme } from "./utils/muiTheme";
import { themeColors } from "./utils/themeColors";
import { useLocalStorage } from "./utils/proprietaryHooks";

//class App extends Component {
const App = () => {
  const [name, setName] = useLocalStorage("_appName");
  var appName = "";
  var tenant = "";

  var request = new XMLHttpRequest();
  request.open("GET", document.location, false);
  request.send(null);
  appName = request.getResponseHeader(APP_HEADER_NAME);
  tenant = request.getResponseHeader(TENANT_HEADER_NAME);

  if (!appName || !themeColors[appName]) {
    appName = "ibuhoo";
  }
  if (name === undefined || name === null) {
    setName(appName);
  }
  const title = apps.find((app) => app.name === appName).title;
  document.title = title ? title : "GSE Suite";

  return (
    <ThemeProvider theme={muiTheme(appName, tenant)}>
      <Provider store={store}>
        <Routes appName={appName} tenant={tenant}></Routes>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
