import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetPassword } from "../../../actions/auth.action";

import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../../helpers/simpleReactValidator";

import PageBackground from "../../../components/pageBackground.component";
import CardBody from "../../../components/CardBody/cardBody.component";
import InputForm from "../../../components/InputForm/inputForm.component";
import PrimaryButton from "../../../components/primaryButton.component";
import ErrorBox from "../../../components/errorBox.component";

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

/**
 * Reset Password Component ( Reset password sending an e-mail
 * form and make a post request for user verification  )
 *
 * @export Class Component
 * @class ResetPassword
 * @extends {Component}
 * @returns Redux connect
 */

export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: { email: "" },
      getAuthenticationResponse: {
        responseCode: "",
      },
      loading: false,
      getErrors: "",
    };

    this.loginValidator = new SimpleReactValidator(reactValidatorOptions);
  }

  setResetPassword = (e) => {
    
    e.preventDefault();
    if (this.loginValidator.allValid()) {
      const { email } = this.state.form;
      let userData = {
        user: email,
        appname: this.props.appName,
      };
      this.props.resetPassword(userData, this.props.history);
    } else {
      this.loginValidator.showMessages();
      this.forceUpdate();
    }
  };
  returnToLogin = (e) => {
    
    e.preventDefault()
    this.props.history.push({
      pathname: '/Login'
    });
  }

  cleanResetForm = () => {
    this.setState({
      form: { email: "" },
      getAuthenticationResponse: {
        responseCode: "",
      },
    });
    this.loginValidator.hideMessages();
  };

  render() {
    return (
      <div className="login">
        <PageBackground></PageBackground>
        <CardBody title="Recordar Contraseña">
          <form
            id="reset-pass"
            onSubmit={(e) => this.setResetPassword(e)}
            autoComplete="off"
          >
            <hr></hr>
            <Typography style={{ textAlign: "center", color: '#706F6F' }}>
              Ingrese su email y recibirá un correo con las instrucciones para
              restablecer su contraseña
              <br />
              <br />
            </Typography>
            <InputForm
              placeholder={" E-mail"}
              icon={"Email"}
              id="email"
              onChange={this.syncChanges}
              value={this.state.form.email}
              validator={this.loginValidator}
              validateOptions={"required|email"}
            />
            <br />
            <Grid container spacing={2} justifyContent='center'>
              <Grid item lg={4}>
                <PrimaryButton
                  name={"cancelar"}
                  txtBtn={"Cancelar"}
                  onClick={this.returnToLogin}
                ></PrimaryButton>
              </Grid>
              <Grid item lg={4}>
                <PrimaryButton
                  name={"enviar"}
                  txtBtn={"Enviar"}
                  loading={this.state.loading}
                ></PrimaryButton>
              </Grid>
            </Grid>



            {this.state.getErrors && (
              <ErrorBox value={this.state.getErrors}></ErrorBox>
            )}
          </form>
        </CardBody>
      </div>
    );
  }

  static getDerivedStateFromProps(nextProps, state) {
    let update = {};
    if (
      !!nextProps.authReducer.getAuthenticationResponse &&
      nextProps.authReducer.getAuthenticationResponse !==
      state.getAuthenticationResponse
    ) {
      update.getAuthenticationResponse =
        nextProps.authReducer.getAuthenticationResponse;
    }
    if (nextProps.loadingReducer.loading !== state.loading) {
      update.loading = nextProps.loadingReducer.loading;
    }
    return Object.keys(update).length ? update : null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.loading !== this.state.loading) {
      this.state.loading
        ? this.setState({ loading: true })
        : this.setState({ loading: false });
    }
    if (
      prevState.getAuthenticationResponse !==
      this.state.getAuthenticationResponse
    ) {
      if (this.state.getAuthenticationResponse.responseCode === 200) {
        this.cleanLoginForm();
      } else {
        this.setState({
          getErrors: this.state.getAuthenticationResponse.message,
        });
      }
    }
  }

  syncChanges = (e) => {
    const id = !!e.target.id ? e.target.id : e.target.name;
    const value = e.target.value;
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [id]: value,
      },
    }));
  };
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired,
  loadingReducer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
  loadingReducer: state.loadingReducer,
});

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
