import { createTheme } from "@material-ui/core/styles";
import { themeColors } from "./themeColors";

export const apps = [
  {
    name: "colfactura",
    title: "Colfactura",
    backgroundImage: "colfacturaBackground.png",
    logo: "colfacturaLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "colfacturaIco.ico",
  },
  {
    name: "GSE Suite",
    title: "Suite GSE",
    backgroundImage: "gseBackground.jpg",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "gsefavicon.ico",
  },
  {
    name: "oidc",
    title: "OIDC",
    backgroundImage: "gseBackground.jpg",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "gsefavicon.ico",
  },
  {
    name: "firmaya",
    title: "Firmaya",
    backgroundImage: "firmayaBackground.png",
    logo: "firmayaLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "firmayaIco.ico",
  },
  {
    name: "ibuhoo",
    title: "Suite GSE",
    backgroundImage: "gseBackground.jpg",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "gsefavicon.ico",
  },
  {
    name: "license",
    title: "License",
    backgroundImage: "gseBackground.jpg",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "gsefavicon.ico",
  },
  {
    name: "colnomina",
    title: "Colfactura Nómina",
    backgroundImage: "colnominaBackground.png",
    logo: "colnominaLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "colfacturaIco.ico",
  },
  {
    name: "biometria",
    title: "Biometria",
    backgroundImage: "gseBackground.jpg",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "gsefavicon.ico",
  },
  {
    name: "confronta",
    title: "Confronta",
    backgroundImage: "gseBackground.jpg",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "gsefavicon.ico",
  },
  {
    name: "myselfcert",
    title: "MySelf",
    backgroundImage: "LoginUko.png",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "gsefavicon.ico",
  },
  {
    name: "bemyself",
    title: "bemyself",
    backgroundImage: "LoginUko.png",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "bemyself.ico",
  },
  {
    name: "bemyself_472",
    title: "bemyself_472",
    backgroundImage: "LoginUko.png",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "bemyself.ico",
  },
  {
    name: "bemyself_bmscol",
    title: "bemyself_bmscol",
    backgroundImage: "LoginUko.png",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "bemyself.ico",
  },
  {
    name: "bemyself_bmsecu",
    title: "bemyself_bmsecu",
    backgroundImage: "LoginUko.png",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "bemyself.ico",
  },
  {
    name: "bemyself_bmsbra",
    title: "bemyself_bmsbra",
    backgroundImage: "LoginUko.png",
    logo: "gseLogo.png",
    whiteGseLogo: "whiteGseLogo.png",
    icon: "bemyself.ico",
  },
];

export const muiTheme = (appName, tenant) => {
  if (tenant) {
    appName = appName + "_" + tenant;
  }
  const { backgroundImage, logo, icon } = apps.filter(
    (item) => item.name === appName
  )[0];
  var favicon = document.getElementById("favicon");
  favicon.href = `/${icon}`;
  return createTheme({
    palette: {
      primary: {
        light: `${themeColors[appName]["primary"]["light"]}`,
        main: `${themeColors[appName]["primary"]["main"]}`,
        dark: `${themeColors[appName]["primary"]["dark"]}`,
        iconSend: `${themeColors[appName]["primary"]["iconSend"]}`,
      },
      secondary: {
        light: `${themeColors[appName]["secondary"]["light"]}`,
        main: `${themeColors[appName]["secondary"]["main"]}`,
        dark: `${themeColors[appName]["secondary"]["dark"]}`, //claro
        titleText:`${themeColors[appName]["secondary"]["titleText"]}`
      },
      info: {
        light: `${themeColors[appName]["info"]["light"]}`,
        main: `${themeColors[appName]["info"]["main"]}`,
        dark: `${themeColors[appName]["info"]["dark"]}`,
      },
      error: {
        light: `${themeColors[appName]["error"]["light"]}`,
        main: `${themeColors[appName]["error"]["main"]}`,
        dark: `${themeColors[appName]["error"]["dark"]}`,
      },
      text: {
        primary: "#575756",
        secondary: "#FFFFFF",
        disabled: "#B2B2B2",
      },
      common: {
        grey: "#A8A8A8",
        black: "#575756",
        white: "#FFFFFF",
      },
    },

    logo: {
      backgroundImage: `url('${logo}')`,
    },
    pageBackground: {
      backgroundImage: `url('${backgroundImage}')`,
      backgroundSize: "cover",
    },
    typography: {
      fontFamily: ["Muli", "Arial", "sans-serif"].join(","),
      colorMessageUko: `${themeColors[appName]["typographyMessageUko"]["textColor"]}`,
    },
    urlLogo: {
      url: `${themeColors[appName]["urlLogo"]["url"]}`,
      urlSignIn: `${themeColors[appName]["urlLogo"]["urlSignIn"]}`,
      urlRememberPasswordlogo: `${themeColors[appName]["urlLogo"]["urlRememberPasswordlogo"]}`,
      urlCheckEmaillogo: `${themeColors[appName]["urlLogo"]["urlCheckEmaillogo"]}`,
      urlRemeberAndInvalid: `${themeColors[appName]["urlLogo"]["urlRemeberAndInvalid"]}`,
      urlInvalidLinkMoreBlue: `${themeColors[appName]["urlLogo"]["urlInvalidLinkMoreBlue"]}`,
      urlChangePasswordlogo: `${themeColors[appName]["urlLogo"]["urlChangePasswordlogo"]}`,
      urlMaintenanceImage: `${themeColors[appName]["urlLogo"]["urlMaintenanceImage"]}`,
      urlWhiteGseLogo: `${themeColors[appName]["urlLogo"]["urlWhiteGseLogo"]}`,
      urlSuccessAlertlogo: `${themeColors[appName]["urlLogo"]["urlSuccessAlertlogo"]}`,
      urlExpireTime: `${themeColors[appName]["urlLogo"]["urlExpireTime"]}`,
      urlCheckEmailAgain: `${themeColors[appName]["urlLogo"]["urlCheckEmailAgain"]}`,
      urlCheckEmailMoreBlue: `${themeColors[appName]["urlLogo"]["urlCheckEmailMoreBlue"]}`,
    },
    InputUko: {
      colorInputLetter: `${themeColors[appName]["InputUko"]["colorInputLetter"]}`,
      borderInput: `${themeColors[appName]["InputUko"]["borderInput"]}`,
      colorLabel: `${themeColors[appName]["InputUko"]["colorLabel"]}`,
      colorLabelFocus: `${themeColors[appName]["InputUko"]["colorLabelFocus"]}`,
      colorIcon: `${themeColors[appName]["InputUko"]["colorIcon"]}`,
      backgroundColor:`${themeColors[appName]["InputUko"]["backgroundColor"]}`
    },
    buttonUko: {
      buttonUkoBackgroundDisabled: `${themeColors[appName]["buttonUko"]["buttonUkoBackgroundDisabled"]}`,
      buttonUkoColorDisabled: `${themeColors[appName]["buttonUko"]["buttonUkoColorDisabled"]}`,
      buttonUkoBackgroundActive: `${themeColors[appName]["buttonUko"]["buttonUkoBackgroundActive"]}`,
      buttonUkoBackgroundSuccess: `${themeColors[appName]["buttonUko"]["buttonUkoBackgroundSuccess"]}`,
      buttonUkoColorDisabledResetPassword: `${themeColors[appName]["buttonUko"]["buttonUkoColorDisabledResetPassword"]}`,
    },
    linkUko: {
      colorLink: `${themeColors[appName]["linkUko"]["colorLink"]}`,
    },
    versionUko: {
      colorVersionLabel: `${themeColors[appName]["versionUko"]["colorVersionLabel"]}`,
    },
    alertUko: {
      imageUko: `${themeColors[appName]["alertUko"]["imageUko"]}`,
      colorTextUko: `${themeColors[appName]["alertUko"]["colorTextUko"]}`,
    },
    forgotPassword: {
      colorForgotPassword: `${themeColors[appName]["forgotPassword"]["colorForgotPassword"]}`,
    },
    selectAccount: {
      colorRoot: `${themeColors[appName]["selectAccount"]["colorRoot"]}`,
      colorChecked: `${themeColors[appName]["selectAccount"]["colorChecked"]}`,
    },
    widthImageLogo: `${themeColors[appName]["widthImageLogo"]}`,
  });
};
